<template>
  <div class="allCategories">
    <div class="header-search">
      <div class="search-l" @click="isShowSelect = !isShowSelect">
        <img class="location" src="./img/location.png" alt="" />
        <span class="text">{{ selectText }}</span>
        <img class="arrow" src="./img/down-arrow.png" alt="" />
      </div>
      <transition name="fade-move">
        <div class="select" v-if="isShowSelect">
          <ul>
            <li
              :class="{ active: curIndex == index }"
              @click="handelSelect(item, index)"
              v-for="(item, index) in sortType"
              :key="index"
            >
              {{ item.title }}
            </li>
            <!-- <li>距离最近</li>
            <li>最新发布</li> -->
          </ul>
        </div>
      </transition>

      <div class="search-r">
        <v-input
          leftIcon="search"
          placeholder="请输入关键字"
          v-model="params.title"
          @blur="handelBlurTitle"
        >
        </v-input>
      </div>
    </div>
    <div class="convenient-publish-recently">
      <div class="publish-content">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        >
          <div
            class="content-detail"
            v-for="(item, index) in serveList"
            :key="index"
            @click="toDetailPage(item.id)"
          >
            <div class="detail-l">
              <img :src="item.coverPicture" alt="" />
            </div>
            <div class="detail-r">
              <div class="detail-r-title">
                {{ item.title }}
              </div>
              <div class="detail-r-describe">
                {{ item.subTitle }}
              </div>
              <div class="detail-r-evaluate">
                <div
                  v-if="item.evaluateScore == 0"
                  class="detail-r-evaluate-status"
                >
                  未评价
                </div>
                <div v-else class="rate">
                  <rate :rate="item.evaluateScore"></rate>
                </div>
              </div>
              <div class="detail-r-serve-detail">
                <div class="time">{{ checkTime(item.createTime) }}</div>
                <div class="btn">{{ item.serviceTypeLv2Name }}</div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getServiceList, getInitInfo, initWxJsSdkUrl } from "./api.js";
import rate from "./rating.vue";
export default {
  name: "allCategories",
  components: {
    rate,
  },
  props: {},
  data() {
    return {
      selectText: "全部",
      finishedText: "没有更多了",
      finished: false,
      searchValue: "",
      currentIndex: 2,
      isShowSelect: false,
      curIndex: 0,
      params: {
        curPage: 1,
        pageSize: 10,
        serviceType: "", //服务类型
        serviceTypeLv2: "", //子分类
        lat: "",
        lng: "",
        sortType: "", //排序方式
        title: "", //标题
      },
      serveList: [],
      sortType: [
        {
          title: "全部",
          value: "",
        },
        {
          title: "评分最高",
          value: 1,
        },
        {
          title: "距离最近",
          value: 2,
        },
        {
          title: "最新发布",
          value: 3,
        },
      ],
      longitude: "",
      latitude: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    const { serviceType, serviceTypeLv2 } = this.$route.query;
    if (serviceType != undefined) {
      this.params.serviceType = serviceType;
      this.params.serviceTypeLv2 = serviceTypeLv2;
    }
    await this.initWxConfig();
    await this.getLocation();
  },
  mounted() {},
  methods: {
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      let ourl;
      if (process.env.NODE_ENV == "production") {
        ourl = location.href.split("#")[0];
      } else {
        ourl = origin;
      }
      let params = {
        url: ourl,
      };
      let url = initWxJsSdkUrl;
      let res = await this.$axios.post(`${url}`, this.$qs.stringify(params));
      if (res.code == 200) {
        this.wxData = res.data;
        console.log("this.wxData ----->", this.wxData);
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: this.wxData.appId, // 必填，公众号的唯一标识
          timestamp: this.wxData.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.wxData.nonceStr, // 必填，生成签名的随机串
          signature: this.wxData.signature, // 必填，签名
          jsApiList: ["checkJsApi", "getLocation"],
        });
      }
    },
    getLocation() {
      let _this = this;
      wx.ready(() => {
        console.log(" wx.ready----->");
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success: (rest) => {
            //获取当前位置
            wx.getLocation({
              type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log("res----->", res);
                _this.latitude = res.latitude;
                _this.longitude = res.longitude;
              },
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },
    checkTime(time) {
      const currentTime = new Date();
      const fixedTime = new Date(time);
      const timeDifference = currentTime - fixedTime;
      const minutesDifference = Math.floor(timeDifference / 60000);
      // 判断当前时间是否在十五分钟前
      if (minutesDifference <= 15 && minutesDifference >= -15) {
        return "刚刚";
      } else {
        // 格式化固定时间
        // const formattedTime = fixedTime.toLocaleString(); // 格式为默认的日期时间格式
        return time;
      }
    },
    handelBlurTitle() {
      this.initCurpage();
      this.getServiceList();
    },
    async handelSelect(item, index) {
      if (item.value === 2) {
        this.params.lat = this.latitude;
        this.params.lng = this.longitude;
      } else {
        this.params.lat = "";
        this.params.lng = "";
      }
      this.params.sortType = item.value;
      this.curIndex = index;
      this.selectText = item.title;
      this.isShowSelect = false;
      await this.initCurpage();
      await this.getServiceList();
    },
    initCurpage() {
      this.params.curPage = 1;
    },
    onLoad() {
      this.getServiceList();
    },
    //获取发布列表页面
    getServiceList() {
      let params = {
        houseId: this.houseId || this.communityId,
        ...this.params,
      };
      this.$axios
        .get(
          `${getServiceList}`,
          { params },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (this.params.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.serveList = [];
              this.finishedText = "";
              return;
            }
            if (this.params.curPage === 1) {
              this.serveList = res.data.records;
            } else {
              const list = this.serveList.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.serveList.push(item);
              });
            }
            this.serveList.forEach((item) => {
              if (item.coverPicture && item.coverPicture.length > 0) {
                item.coverPicture = this.$handleImg(
                  224,
                  220,
                  item.coverPicture
                );
              }
            });

            this.params.curPage++;
          }
        });
    },
    //详情页面
    toDetailPage(id) {
      this.$router.push({
        name: "convenientServiceDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.allCategories {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 30px 0 30px;
  width: 100%;
  min-height: 100%;
  padding-bottom: calc(68px + constant(safe-area-inset-bottom));
  padding-bottom: calc(68px + env(safe-area-inset-bottom));
  .header-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    box-sizing: border-box;
    .select {
      z-index: 999;
      position: absolute;
      top: 90px;
      width: 206px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      left: 30px;
      ul {
        position: relative;
        padding: 26px 0 24px 0;
        // &::before {
        //   content: "";
        //   width: 0;
        //   height: 0;
        //   border-left: 20px solid transparent;
        //   border-right: 20px solid transparent;
        //   border-bottom: 20px solid #fff;
        //   position: absolute;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   top: -11px;
        // }
      }
      li {
        text-align: center;

        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        &.active {
          color: #5390f9;
        }
      }
    }
    .fade-move-leave-active,
    .fade-move-enter-active {
      transition: all 0.15s;
    }
    .fade-move-enter {
      opacity: 1;
      transform: translateY(-10px);
    }
    .fade-move-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
    .search-l {
      width: 240px;
      display: flex;
      align-items: center;
      .location {
        width: 40px;
        height: 40px;
      }
      .text {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0 24px 0 14px;
      }
      .arrow {
        width: 14px;
        height: 10px;
      }
    }
    .search-r {
      flex: 1;
      max-width: calc(100% - 240px);
      height: 62px;
      overflow: hidden;
      > div {
        width: 100%;
        height: 100%;
        ::v-deep .van-cell {
          overflow: hidden;
          border-radius: 31px;
          background: #f7f7f7;
          width: 100%;
          height: 100%;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 40px;
          padding: 0;
          padding-left: 44px;
          display: flex;
          align-items: center;
          .van-field__left-icon {
            margin-right: 4px;
            margin-top: 6px;
            .van-icon {
              font-size: 28px;
            }
          }
        }
        ::v-deep .van-field__control::-webkit-input-placeholder {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
      }
    }
  }
  .convenient-publish-recently {
    width: 100%;
    box-sizing: border-box;

    .publish-content {
      width: 100%;
      box-sizing: border-box;
      .content-detail {
        width: 100%;
        height: 220px;
        display: flex;
        align-items: stretch;
        gap: 22px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        overflow: hidden;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .detail-l {
          width: 224px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detail-r {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: calc(100% - 240px);
          box-sizing: border-box;
          padding-top: 24px;
          .detail-r-title {
            padding-right: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
          }
          .detail-r-describe {
            padding-right: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 8px 0 14px 0;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
          }
          .detail-r-evaluate {
            .detail-r-evaluate-status {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 110px;
              height: 38px;
              border-radius: 19px;
              border: 2px solid #ffd343;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffd343;
              line-height: 34px;
            }
            .rate {
              width: 206px;
              overflow: hidden;
              position: relative;
              height: 32px;
            }
          }
          .detail-r-serve-detail {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .time {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #c2c2c2;
              line-height: 34px;
            }
            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 194px;
              height: 62px;
              background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
              box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
              border-radius: 16px 0px 16px 0px;
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
}
</style>
